<template>
  <div v-if="connections" class="padding-xy-0 padding-xy-5-tablet">
    <b-table
      :data="connections"
      class="scrollable clickable-row"
      :mobile-cards="false"
      :hoverable="true"
      @click="goToConnection">
      <b-table-column v-slot="props" field="connection_name" label="Connection" width="100">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="logo"
        label="Logo"
        width="150"
        style="min-width: 150px">
        <img :src="(props.row.images.find(i => i.name === 'Logo') || {}).screenshotUrl" style="height:40px">
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="icon"
        label="Icon"
        width="50"
        style="min-width: 80px">
        <img :src="(props.row.images.find(i => i.name === 'Icon') || {}).screenshotUrl" style="height:40px">
      </b-table-column>
      <!-- <b-table-column
         v-slot="props"
          field="rendering"
          label="Rendering"
          width="150"
          style="min-width: 150px">
          <img :src="getRenderImg(props.row.id)" style="height:40px">
        </b-table-column> -->
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Connections',
  data () {
    const connectionsColumns = [
      {
        field: 'connection_name',
        label: 'Connection'
      },
      {
        field: 'logo',
        label: 'Logo'
      },
      {
        field: 'icon',
        label: 'Icon'
      }
      // {
      //   field: 'rendering',
      //   label: 'Rendering'
      // }
    ]
    return {
      connectionsColumns
    }
  },
  computed: {
    connections () {
      return this.$store.state.connections
    }
  },
  created () {
    this.$store.commit('setTitle', 'Connections')
    this.$store.dispatch('refreshConnections')
  },
  methods: {
    getRenderImg (id) {
      // determine new connection render image
      const tncConnections = [1, 2, 3, 4, 6, 7, 8]
      if (tncConnections.includes(id)) {
        return require('@/assets/images/connection_type_renderings/Tube_and_Coupling.jpg')
      } else if (id === 9) {
        return require('@/assets/images/connection_type_renderings/Plain_End.jpg')
      } else if (id === 10) {
        return require('@/assets/images/connection_type_renderings/Semiflush.jpg')
      }
    },
    goToConnection (connection) {
      this.$router.push({
        name: 'manage_connection',
        params: { id: connection.id }
      })
    }
  }
}
</script>
